<template>
  <section class="invoice-add-wrapper">
    <b-breadcrumb class="mb-1">
      <b-breadcrumb-item href="/">
        <b-icon
          icon="house-fill"
          scale="1.25"
          shift-v="1.25"
          aria-hidden="true"
        ></b-icon>
        Dashboard
      </b-breadcrumb-item>
      <b-breadcrumb-item v-on:click="goBack"
        >Facture récurrentes</b-breadcrumb-item
      >
      <b-breadcrumb-item active>Édition de Facture</b-breadcrumb-item>
    </b-breadcrumb>
    <b-row class="invoice-add">
      <b-col cols="12" xl="9" md="8">
        <b-card>
          <b-card no-body class="invoice-preview-card">
            <b-card-body class="invoice-padding pb-0">
              <div
                class="
                  d-flex
                  justify-content-between
                  flex-md-row flex-column
                  invoice-spacing
                  mt-0
                "
              >
                <div>
                  <div class="logo-wrapper">
                    <b-img
                      :src="enterprise.logo"
                      width="64"
                      alt="placeholder"
                    ></b-img>
                    <h3 class="text-primary invoice-logo">
                      {{ enterprise.name }}
                    </h3>
                  </div>

                  <b-card-text class="mb-25">
                    {{ enterprise.address }} - {{ enterprise.country.name }}
                  </b-card-text>
                </div>
                <div>
                  <table>
                    <tbody>
                      <tr>
                        <td class="pr-1">Date :</td>
                        <td>
                          {{
                            new Date(invoice.created_at)
                              .toISOString()
                              .split("T")[0]
                          }}
                        </td>
                      </tr>
                      <hr />
                      <tr class="mt-1">
                        <td class="pr-1">N° Facture :</td>
                        <td>
                          <b-input-group
                            class="
                              input-group-merge
                              invoice-edit-input-group
                              disabled
                            "
                          >
                            <b-input-group-prepend is-text>
                              <!-- <feather-icon icon="HashIcon" /> -->
                            </b-input-group-prepend>
                            <b-form-input
                              disabled
                              v-model="invoice.num"
                              id="invoice-data-id"
                            />
                          </b-input-group>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="invoice-number-date mt-md-0 mt-2">
                  <div
                    class="
                      d-flex
                      align-items-center
                      justify-content-md-end
                      mb-1
                    "
                  ></div>
                </div>
              </div>
            </b-card-body>
            <hr class="invoice-spacing" />

            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <b-col cols="12" xl="6" class="mb-lg-1">
                  <h6 class="mb-2">Client :</h6>

                  <!-- Select Client -->
                  <multiselect
                    v-model="customerMultiSelect"
                    placeholder="Veuillez choisir un client"
                    :options="customers"
                    :custom-label="customerName"
                    selectLabel=""
                    deselectLabel=""
                    :showNoResults="false"
                  />

                  <!-- Selected Client -->
                  <div class="mt-1" v-if="myCustomer.name != ''">
                    <b-card-text class="mb-25"
                      ><b>Nom :</b> {{ myCustomer.name }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      <b>Adresse :</b> {{ myCustomer.address }}
                    </b-card-text>
                    <b-card-text class="mb-25"
                      ><b>Téléphone :</b> {{ myCustomer.telephone }}
                    </b-card-text>
                    <b-card-text class="mb-0"
                      ><b>Email :</b> {{ myCustomer.email }}</b-card-text
                    >
                  </div>
                  <div class="mt-1" v-else>
                    <b-card-text class="mb-25"
                      ><b>Nom :</b> {{ invoice.customer.name }}
                    </b-card-text>
                    <b-card-text class="mb-25">
                      <b>Adresse :</b> {{ invoice.customer.address }}
                    </b-card-text>
                    <b-card-text class="mb-25"
                      ><b>Téléphone :</b> {{ invoice.customer.telephone }}
                    </b-card-text>
                    <b-card-text class="mb-0"
                      ><b>Email :</b> {{ invoice.customer.email }}</b-card-text
                    >
                  </div>
                </b-col>

                <!-- Col: Payment Details -->
                <b-col
                  xl="6"
                  cols="12"
                  class="mt-xl-0 mt-2 justify-content-end d-xl-flex d-block"
                >
                  <div class="col-md-6">
                    <b-form-group label="TVA(%)"
                      ><b-form-input v-model="invoice.tva" />
                    </b-form-group>
                    <b-form-group label="Remise(%)"
                      ><b-form-input v-model="invoice.discount" />
                    </b-form-group>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>
            <b-card-body class="">
              <div class="mb-2 table-responsive">
                <table class="table table-striped">
                  <thead>
                    <tr>
                      <th scope="col">Prestation</th>
                      <th scope="col">Prix</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="service in invoice.prestations"
                      :key="service.id"
                    >
                      <td>
                        {{ service.name }}
                      </td>
                      <td>
                        {{ service.price }}
                      </td>
                      <td class="d-flex justify-content-between">
                        <span
                          @click="editThisPrestation(service)"
                          class="text-success ky-btn"
                        >
                          <feather-icon icon="EditIcon"
                        /></span>
                        <span
                          @click="deletePrestation(service)"
                          class="text-danger ky-btn"
                        >
                          <feather-icon icon="XIcon"
                        /></span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </b-card-body>

            <div>
              <validation-observer ref="serviceForm">
                <b-form class="repeater-form">
                  <b-row ref="row">
                    <b-col md="6">
                      <validation-provider
                        #default="{ errors }"
                        name="Prestation"
                        rules="required"
                      >
                        <b-form-group label="Prestation" label-for="item-name">
                          <b-form-input
                            :disabled="invoice.num == '' ? true : false"
                            id="item-name"
                            type="text"
                            v-model="service.name"
                            placeholder="Veuillez saisir la prestation à facturer"
                          />
                          <small v-if="invoice.num == ''" class="text-danger"
                            ><feather-icon icon="AlertTriangleIcon" size="14" />
                            Veuillez d'abord sélectionner un client</small
                          ><br />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col lg="4" md="1">
                      <validation-provider
                        name="Prix"
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group label="Prix" label-for="price">
                          <b-form-input
                            :disabled="invoice.num == '' ? true : false"
                            v-model="service.price"
                            id="price"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>

                    <b-col lg="2" md="3" class="mb-50 mt-0 mt-md-2">
                      <!-- save prestation component -->
                      <b-avatar
                        v-if="isPrestationEditMode == false"
                        :disabled="serviceSaving"
                        button
                        variant="success"
                        @click="saveRecurringInvoiceService()"
                        size="2rem"
                        ><b-spinner v-if="serviceSaving" small />
                        <feather-icon v-else icon="SaveIcon"
                      /></b-avatar>
                      <!-- end save prestation component -->
                      <!-- edit prestation component -->
                      <b-avatar
                        v-if="isPrestationEditMode == true"
                        :disabled="serviceSaving"
                        button
                        variant="success"
                        @click="editPrestation()"
                        size="2rem"
                        ><b-spinner v-if="serviceSaving" small />
                        <feather-icon v-else icon="EditIcon"
                      /></b-avatar>
                      <!-- end edit prestation component -->
                      <!-- <b-avatar
                        button
                        variant="danger"
                        @click="removeItem(index)"
                        size="2rem"
                        ><feather-icon icon="XIcon"
                      /></b-avatar> -->
                    </b-col>
                    <b-col cols="12">
                      <hr />
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>
            </div>
            <!-- <div class="md-3 text-right">
              <b-button
                size="sm"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="repeateAgain"
              >
                <feather-icon icon="PlusIcon" class="mr-25" />
                <span>Ajouter une prestation</span>
              </b-button>
            </div> -->
            <b-card-body class="invoice-padding pb-0 mt-2">
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-0 mt-3 d-flex align-items-center"
                  order="2"
                  order-md="1"
                >
                </b-col>

                <!-- Col: Total -->
                <b-col
                  cols="12"
                  md="6"
                  class="mt-md-6 d-flex justify-content-end"
                  order="1"
                  order-md="2"
                >
                  <div class="invoice-total-wrapper">
                    <hr class="my-50" />
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Total HT :</p>
                      <p class="invoice-total-amount">{{ invoiceTotal() }}€</p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">TVA:</p>
                      <p class="invoice-total-amount">{{ invoice.tva }} %</p>
                    </div>
                    <div class="invoice-total-item">
                      <p class="invoice-total-title">Total TTC:</p>
                      <p class="invoice-total-amount">{{ invoiceTTC() }}€</p>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <!-- Spacer -->
            <hr class="invoice-spacing" />

            <!-- Note -->
          </b-card>
        </b-card>
      </b-col>
      <b-col cols="12" md="4" xl="3" class="invoice-actions mt-md-0 mt-2">
        <div class="mt-2">
          <b-form-group label="Envoyer par :" label-for="payment-method">
          </b-form-group>
          <b-form-group>
            <b-form-select v-model="invoice.frequence">
              <b-form-select-option :value="null"
                >Choisir une options d'envoie</b-form-select-option
              >
              <b-form-select-option value="d">Jour</b-form-select-option>
              <b-form-select-option value="w">Semaine</b-form-select-option>
              <b-form-select-option value="m">Mois</b-form-select-option>
              <b-form-select-option value="y">Année</b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group v-if="invoice.frequence != null" :label="'Frequence'">
            <b-form-input type="number" v-model="invoice.every" />
          </b-form-group>

          <div>
            <b-form-group label="Date de début :">
              <b-form-input type="date" v-model="invoice.start_date" />
            </b-form-group>
          </div>
          <div>
            <b-form-group label="Date de fin :">
              <b-form-input type="date" v-model="invoice.end_date" />
            </b-form-group>
          </div>
          <div
            v-if="
              invoice.frequence != null &&
              invoice.every != '' &&
              invoice.start_date != '' &&
              invoice.end_date != ''
            "
          >
            <b-card>
              <h3>
                <feather-icon icon="AlertTriangleIcon" size="18" /> Information
              </h3>
              <hr />
              <p class="lh-lg">
                Cette facture sera envoyée tous les
                <b
                  >{{ invoice.every }}
                  {{
                    invoice.frequence == "d"
                      ? "jour"
                      : invoice.frequence == "w"
                      ? "semaine"
                      : invoice.frequence == "m"
                      ? "mois"
                      : "année"
                  }}
                </b>
                à compter du <b>{{ invoice.start_date }}</b> jusqu'au
                <b> {{ invoice.end_date }} </b>.
              </p>
            </b-card>
          </div>
        </div>
        <b-card>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            :disabled="loading"
            @click="updateRecurringInvoice"
          >
            Sauvegarder
          </b-button>
          <!-- <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="mb-75"
            block
          >
            Prévisualiser
          </b-button> -->
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { VBToggle } from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { mapState, mapActions, mapGetters } from "vuex";
import Multiselect from "vue-multiselect";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    var today = new Date();
    return {
      isPrestationEditMode: false,
      invoice_num: 0,
      theSum: 0,
      loading: false,
      serviceSaving: false,
      thisRecurringInvoice: {},
      isDisable: false,
      enterprise: {},
      today,
      customerMultiSelect: null,
      myCustomer: {
        name: "",
        address: "",
        telephone: 0,
        email: "",
      },
      invoice: {},
      service: {
        name: "",
        price: "",
      },
      serviceDefault: {
        name: "",
        price: "",
      },

      nextTodoId: 2,
      clients: {},
    };
  },

  components: {
    flatPickr,
    vSelect,
    Multiselect,
    ToastificationContent,
  },

  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },

  computed: {
    ...mapState("enterprise", { customers: (state) => state.clients }),
  },
  watch: {
    customerMultiSelect(val) {
      this.myCustomer = this.customerMultiSelect;
      if (this.myCustomer.name != "") {
        this.invoice.customer_id = this.myCustomer.id;
        this.editRecurringInvoice(this.invoice).then((response) => {
          this.thisRecurringInvoice = Object.assign(response.data);
        });
      }
    },
  },

  mounted() {
    this.showThisAllPrestations();

    this.getInfo().then((r) => {
      this.enterprise = r.data.data;
    });

    this.fetchCustomers();
  },

  methods: {
    ...mapActions("enterprise", ["fetchCustomers", "addCustomer"]),
    ...mapActions("enterprise", ["getInfo"]),
    ...mapActions("tasks", [
      "editRecurringInvoice",
      "createRecurringInvoiceService",
      "showRecurringInvoice",
      "deleteRecurringInvoicePrestation",
      "editRecurringInvoicePrestation",
    ]),

    goBack() {
      history.back();
    },

    deletePrestation(data) {
      this.deleteRecurringInvoicePrestation(data.id).then((e) => {
        this.showThisAllPrestations();
      });
    },

    showThisAllPrestations() {
      this.showRecurringInvoice(this.$route.params.num).then((res) => {
        this.invoice = res.data;
      });
    },

    invoiceTotal() {
      var sum = 0;
      for (let item = 0; item < this.invoice.prestations.length; item++) {
        if (this.invoice.prestations[item].price == null) {
          return "0";
        } else {
          sum += Number(this.invoice.prestations[item].price);
        }
      }
      this.theSum = sum - (sum * this.invoice.discount) / 100;
      return sum
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    invoiceTTC() {
      return (this.theSum + (this.theSum * this.invoice.tva) / 100)
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },

    customerName({ name }) {
      return name;
    },

    updateRecurringInvoice() {
      this.loading = true;
      this.invoice.total = this.invoiceTTC();
      this.editRecurringInvoice(this.$formData(this.invoice))
        .then((res) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Facture modifiée avec succès",
              icon: "AlertCircleIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erreur de validation",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },

    editThisPrestation(data) {
      this.service = data;
      this.isPrestationEditMode = true;
    },

    editPrestation() {
      this.editRecurringInvoicePrestation(this.$formData(this.service)).then(
        (res) => {
          this.clearForm();
          this.isPrestationEditMode = false;
        }
      );
    },

    async saveRecurringInvoiceService() {
      const valid = await this.$refs.serviceForm.validate();
      if (!valid) return;
      this.serviceSaving = true;
      this.service.recurring_invoice_id = this.invoice.id;
      this.createRecurringInvoiceService(this.$formData(this.service)).then(
        (res) => {
          this.invoice.prestations.push(this.service);
          this.clearForm();
          this.serviceSaving = false;
        }
      );
    },

    clearForm() {
      this.service = this.serviceDefault;
      this.$nextTick().then((response) => {
        this.$refs.serviceForm.reset();
      });
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
.ky-btn {
  cursor: pointer;
}
</style>
